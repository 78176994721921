/*@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');*/

.row-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #EFEFF4;
    overflow: hidden;
}


.first-row {
    background-color: #EFEFF4;
}

.second-row {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
    margin: 5px;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
}

.info-msg {
    color: #059;
    background-color: #BEF;
}

.success-msg {
    color: #270;
    background-color: #DFF2BF;
}

.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

.web-chat {
    overflow: hidden;
}

.connect-spinner {
    display: flex;
    margin: auto;
    text-align: center;
}

    .connect-spinner > .content {
        margin: auto;
    }

        .connect-spinner > .content > .icon {
            font-size: 64px;
        }

