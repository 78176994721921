
.minimizable-web-chat  button.minimize {
    background-color: #001E55;
    border-radius: 50%;
    border: 2px solid #ffffff;
    bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    color: White;
    font-size: 30px;
    height: 64px;
    outline: 0;
    padding: 15px;
    position: fixed;
    right: 20px;
    width: 64px;
}

    .minimizable-web-chat button.minimize:focus:not(:active),
    .minimizable-web-chat button.minimize:hover:not(:active) {
        background-color: #231EDC;
    }

.minimizable-web-chat > button.maximize {
    background-color: #001E55;
    border-radius: 50%;
    border: 2px solid #ffffff;
    bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    color: White;
    font-size: 30px;
    height: 64px;
    outline: 0;
    padding: 15px;
    position: fixed;
    right: 20px;
    width: 64px;
}

    .minimizable-web-chat > button.maximize > .red-dot {
        color: #EF0000;
        font-size: 14px;
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .minimizable-web-chat > button.maximize:focus:not(:active),
    .minimizable-web-chat > button.maximize:hover:not(:active) {
        background-color: #231EDC;
    }

    .minimizable-web-chat > button.maximize:active {
        background-color: #001E55;
    }

.minimizable-web-chat > .chat-box {
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    border-radius: 4px;
    box-shadow: 0 0 5px #ffffff;
    border: 4px solid #001E55;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 320px;
    position: fixed;
    top: 20px;
    width: 30%;
    height: calc(100vh - 6rem - 1.5rem * 3)
}

    .minimizable-web-chat > .chat-box.hide {
        display: none;
    }

    .minimizable-web-chat > .chat-box.left {
        left: 20px;
    }

    .minimizable-web-chat > .chat-box.right {
        right: 20px;
    }

    .minimizable-web-chat > .chat-box > header {
        background-color: #001E55;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        height: 3.875rem;
        justify-content: space-between;
        position: relative;
        width: 100%;
        z-index: 100;
    }

        .minimizable-web-chat > .chat-box > header > .chat-title {
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            height: 100%;
            white-space: nowrap;
        }

            .minimizable-web-chat > .chat-box > header > .chat-title > .recipient-title {
                color: white;
                display: inline-block;
                font-size: 1rem;
                font-weight: 600;
                margin-left: 0.375rem;
                margin-right: 0.5rem;
                max-width: 40vw;
                opacity: 1;
                white-space: nowrap;
            }

            .minimizable-web-chat > .chat-box > header > .chat-title > .header-circle {
                background-color: white;
                border-radius: 50%;
                display: inline-block;
                height: 2.625rem;
                margin-left: 0.5625rem;
                mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
                position: relative;
                width: 2.625rem;
            }

                .minimizable-web-chat > .chat-box > header > .chat-title > .header-circle > .header-icon {
                    background-position: 50% center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    inset: 0px 0px 0px 0px;
                    height: 100%;
                    position: absolute;
                    transform: scale(.8);
                    transition: transform 0.5s;
                    width: 100%;
                }

        .minimizable-web-chat > .chat-box > header > .filler {
            flex: 0 10000 100%;
        }



        .minimizable-web-chat > .chat-box > header button {
            width: 40px;
            height: 40px;
            background-color: Transparent;
            border: 0;
            color: White;
            outline: 0;
            font-size: larger;
        }

            .minimizable-web-chat > .chat-box > header > button:focus:not(:active),
            .minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
                color: rgba(255, 255, 255, .6);
            }

            .minimizable-web-chat > .chat-box > header > button:focus:active {
                color: rgba(255, 255, 255, .4);
            }

    .minimizable-web-chat > .chat-box > .react-web-chat {
        flex: 1;
        background: black;
        height: 100%;
        width: 100%;
    }
